
export default {
    webName:"醺然星球", //网站名
    appName:"醺然星球",  //应用名
    appName_full:"醺然星球APP",  //应用名 全名
    appName_full_en:"Drunken Planet APP",  //应用名 英文
    short_desc:"专为年轻人准备的趣味", //简短介绍
    description:"孤独伫立在都市中的你，拿着手中的酒，喝完这杯还有三杯，却始终找不到一个好玩的喝酒游戏！从你踏上【醺然星球】的这一片土地开始！我不再允许你这样！",
    home:"主页",     //导航
    about:"关于",    //导航
    download:"下载", //导航
    download_url:"http://www.baidu.com", //下载链接
    beian:"粤ICP备19125695号",  //备案号
    company:"广州优息鸟科技有限公司", // 公司名
    img:{
      logo:"./xr-logo.png", // app logo 图标
      web_logo:"./xr-logo.png", // 网站 logo 图标
      logo:"./xr-logo.png", //logo 图标
      score:"./score.png", //评分 图标
      circle:"./circle.png", //背景圆  图标
      android_frame:"./android_frame.png", // 安卓边框
      ios_frame:"./ios_frame.png", //ios 边框
      android_code:"./ma.png", // 安卓下载二维码
      ios_code:"./ma.png",     // ios 下载二维码
      app_img_1:"./xr-index.png", //  主页介绍图 1
      app_img_2:"./xr-name.png", //  主页介绍图 2
      app_img_3:"./xr-ray.png", //  关于 介绍图 1
      app_img_4:"./xr-rotate.png", //  关于 介绍图 2  
    },
    about_text:[ //关于的介绍文本
      "★【醺然星球】是只属于酒鬼的世界，还不用交税（免费使用）；",
      "★在这里，唯一能限制你的只有你的酒量",
      "★在这里，我们为你准备了无数款有趣的喝酒游戏，包括你玩过的没玩过的，听过或没听过的，这里都有。",
      "★在这里，我们为你准备了最合适你的酒吧，让你随时随地享受酒意。",
      "★在这里，你可以找到一起喝酒的有趣酒友，一起玩一些没玩过的喝酒游戏",
      "★在这里你可以记录贪杯的日子，打卡喜欢的酒吧，分享醺然的醉意…",
      "★你还在等什么？还有很多好玩的事好玩的人，在【醺然星球】等着你。",
    ],
    
}

// 心跳
// export default {
//   appName:"心跳空间",  //应用名
//   appName_full:"心跳空间APP",  //应用名 全名
//   appName_full_en:"STRANGER SOCIAL APP",  //应用名 英文
//   short_desc:"真实靠谱颜值担当的交友平台", //简短介绍
//   description:"心跳空间是一款快速真实靠谱的交友平台。用户发布的照片视频都经过人工审核以确保真实性。男性用户付费制加入，保证高质量靠谱。女性用户真实热情不高冷，提升约会效率。随时随地打开心跳空间快速直接和你附近的男神女神约会吧！",
//   home:"主页",     //导航
//   about:"关于",    //导航
//   download:"下载", //导航
//   download_url:"http://www.baidu.com", //下载链接
//   beian:"粤ICP备2021016654号",  //备案号
//   company:"广州星亚云海网络科技", // 公司名
//   img:{
//     logo:"./logo.png", //logo 图标
//     score:"./score.png", //评分 图标
//     circle:"./circle.png", //背景圆  图标
//     android_frame:"./android_frame.png", // 安卓边框
//     ios_frame:"./ios_frame.png", //ios 边框
//     android_code:"./ma.png", // 安卓下载二维码
//     ios_code:"./ma.png",     // ios 下载二维码
//     app_img_1:"./app_img_1.png", //  主页介绍图 1
//     app_img_2:"./app_img_2.png", //  主页介绍图 2
//     app_img_3:"./app_img_3.png", //  关于 介绍图 1
//     app_img_4:"./app_img_4.png", //  关于 介绍图 2  
//   },
//   about_text:[ //关于的介绍文本
//     "★快速交友的平台",
//     "快速找到真实可靠的男神女神进行交友",
//     "★丰富的交友节目",
//     "在广播里发布各种类型的交友节目,清晰直接高效",
//     "★完善的隐私保护",
//     "阅后即焚&申请查看, 你可以只给喜欢的人看到你的资料",
//     "★实时距离与时间",
//     "推荐附近的男神女神, 显示对方的实时距离和登录时间",
//     "★真实可靠的用户",
//     "视频认证确保女士真实性,邀请付费制确保男士可靠性"
//   ],
  
// }