import Vue from "vue";
import VueRouter from "vue-router";
// import  Homes from "../views/Homes.vue";
import  Home from "../views/Home.vue";
import  Space from "../views/Space.vue";
import  About from "../views/About.vue";
import  Download from "../views/Download.vue";
import agreement from '@/views/agreement'
import clause from '@/views/clause'
import logout from '@/views/logout'
import greenClause from '@/views/greenClause'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:'/home',
   
  },
  // {
  //   path: "/homes",
  //   component:Homes,
   
  // },
  {
    path: "/home",
    component:Home,
    children:[
      { path: '', redirect:'/home/space'},
      { path: 'space', component: Space},
      { path: 'about', component: About },
      { path: 'download', component: Download },
    ]
  },
  { //隐私协议
    path:'/Agreement',
    component:agreement
  },
  { //服务条款
    path:'/Clause',
    component:clause
  },
  {//注销须知
    path:'/logout',
    component:logout
  },
  { //绿色条款
    path:'/greenClause',
    component:greenClause
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  linkActiveClass: 'isactive',
  routes
});

export default router;
