<template>
	<div id="download">
		<div class="city">
			<img :src="logo" class="heart">
			<div class="damp">{{$bus.appName}}</div>
			<div class="stage">{{$bus.short_desc}}</div>
		</div>
		<div class="version">
			<div class="qrcode">
				<div>
					<img :src="circle" class="circle1">
					<div class="android">
						<div><img :src="android_code"><img :src="android_frame"></div>
					</div>
				</div>

				<div>
					<div class="iphone">
						<div><img :src="ios_code"><img :src="ios_frame"></div>
					</div>
					<img :src="circle" class="circle2">
				</div>
			</div>
		</div>
		<div class="copy">
			<span>
				Copyright ©{{year}} {{company}} 增值电信业务经营许可证:<a href="https://beian.miit.gov.cn/"> {{size}} </a>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				size: this.$bus.beian,
				company: this.$bus.company,
				logo: this.$context(this.$bus.img.logo), //logo
				circle: this.$context(this.$bus.img.circle),
				android_code: this.$context(this.$bus.img.android_code),
				ios_code: this.$context(this.$bus.img.ios_code),
				android_frame: this.$context(this.$bus.img.android_frame),
				ios_frame: this.$context(this.$bus.img.ios_frame),
			}
		},
		computed: {
			year() {
				return new Date().getFullYear();
			}
		}
	}
</script>

<style lang="less">
	#download {
		margin-top: 7.547169px;

		.city {

			display: flex;
			align-items: center;
			flex-direction: column;

			.heart {
				width: 48.113207px;
				height: 48.113207px;
				margin-top: 15.094339px;
				box-shadow: 0 1.886792px 9.433962px #eb869234;

				border-radius: 9.433962px;
			}

			.damp {
				font-weight: bold;
				color: #010110;
				line-height: 8.867924px;
				font-size: 6.037735px;
				margin-top: 3.773584px;
			}

			.stage {
				color: #505050;
				font-size: 4.528301px;
				line-height: 6.792452px;
				line-height: 6.792452px;
			}
		}

		.version {
			position: relative;
			height: 188.679245px;

			.qrcode {
				display: flex;
				justify-content: center;

			}

			.qrcode>div {
				position: relative;
			}

			.circle1,
			.circle2 {
				position: relative;
				width: 110.377358px;
				height: 110.377358px;
			}

			.circle2 {
				//  top: 321px;
				//  left: 206px;、
				top: -26.792452px;
				left: -10.377358px;
			}

			.circle1 {
				top: 60.566037px;
				left: -31.132075px;

			}

			.iphone>div img:first-child {
				width: 45.283018px;
				position: absolute;
				height: 45.283018px;
				top: 58.490566px;
				z-index: 100;
				left: 20.754716px;

			}

			.iphone>div img:last-child {
				top: 20px;
				position: absolute;
				width: 94.339622px;
				height: 103.962264px;
				left: -3.773584px;

			}

			.android>div img:first-child {
				position: absolute;
				top: 58.490566px;
				z-index: 100;
				left: 33.018867px;
				width: 45.283018px;
				height: 45.283018px;
			}

			.android>div img:last-child {
				top: 20px;
				position: absolute;
				width: 94.339622px;
				height: 103.962264px;
				left: 7.547169px;
			}

			//      .android  >div {
			//          display: flex;
			//          justify-content: center;
			//      }
			.android,
			.iphone {
				position: absolute;
			}

			.android>div,
			.iphone>div {
				position: relative;

			}

			.android {
				left: -3.773584px;
				top: 0px;
			}

			.iphone {

				left: 7.547169px;
				top: 0px;
			}


		}

		.copy {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}
	
	@media screen and (max-width:992px) {
		#download {
			margin-top: 47.547169px;
		
			.city {
				display: flex;
				align-items: center;
				flex-direction: column;
		
				.heart {
					width: 100.113207px;
					height: 100.113207px;
					margin-top: 15.094339px;
					box-shadow: 0 1.886792px 9.433962px #eb869234;
		
					border-radius: 9.433962px;
				}
		
				.damp {
					font-weight: bold;
					color: #010110;
					// line-height: 8.867924px;
					font-size: 16.037735px;
					margin-top: 13.773584px;
				}
		
				.stage {
					margin-top: 15px;
					color: #505050;
					font-size: 14.528301px;
				}
			}
		
			.version {
				position: relative;
				height: 188.679245px;
		
				.qrcode {
					display: flex;
					justify-content: center;
		
				}
		
				.qrcode>div {
					position: relative;
				}
		
				.circle1,
				.circle2 {
					position: relative;
					width: 110.377358px;
					height: 110.377358px;
				}
		
				.circle2 {
					//  top: 321px;
					//  left: 206px;、
					top: -26.792452px;
					left: -10.377358px;
				}
		
				.circle1 {
					top: 60.566037px;
					left: -31.132075px;
		
				}
		
				.iphone>div img:first-child {
					width: 100.283018px;
					position: absolute;
					height: 100.283018px;
					top: 78.490566px;
					z-index: 100;
					left: 30.754716px;
		
				}
		
				.iphone>div img:last-child {
					top: 20px;
					position: absolute;
					width: 170.339622px;
					height: 186.962264px;
					left: -3.773584px;
		
				}
		
				.android>div img:first-child {
					position: absolute;
					top: 78.490566px;
					z-index: 100;
					left: -25.018867px;
					width: 100.283018px;
					height: 100.283018px;
				}
		
				.android>div img:last-child {
					top: 20px;
					position: absolute;
					width: 170.339622px;
					height: 186.962264px;
					left: -60.547169px;
				}
		
				//      .android  >div {
				//          display: flex;
				//          justify-content: center;
				//      }
				.android,
				.iphone {
					position: absolute;
				}
		
				.android>div,
				.iphone>div {
					position: relative;
		
				}
		
				.android {
					left: -3.773584px;
					top: 0px;
				}
		
				.iphone {
		
					left: 7.547169px;
					top: 0px;
				}
		
		
			}
		
		.copy {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			padding-top: 150.320754px;
			padding-bottom: 11.320754px;
			font-size: 8px;
		}
		}
	}
</style>
