import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import constant from "@/utils/constant"
const context = require.context('@/assets/images', true, /\.png|.jpg$/);   //扫描 路径下的图片

Vue.config.productionTip = false;
import 'amfe-flexible/index.js'

// 挂载公共类 方法 和  常量 
Vue.prototype.$bus=constant      // 页面常量
Vue.prototype.$context=context   // 图片扫描内容 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
