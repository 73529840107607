<template>
	<div id="home">

		<div class="head">
			<div class="space">
				<div class="name">{{ $bus.appName_full_en}}</div>
				<div class="title">{{ $bus.appName_full}}</div>
				<div class="text"> {{$bus.description}}</div>
				<button class="download" @click="openUrl()">Download</button>
				<div class="score">
					<div class="ment">软件评分</div>
					<img :src="score">
					<div class="mount">全渠道共2.3k次下载</div>
				</div>
			</div>
			<div class="introduce">
				<img :src="circle" class="circle1">
				<img :src="circle" class="circle2">
				<img :src="app_img_1" class="friend">
				<img :src="app_img_2" class="home">
			</div>
		</div>
		<div class="copy">
			<span>
				Copyright ©{{year}} {{company}} 增值电信业务经营许可证:<a href="https://beian.miit.gov.cn/"> {{size}} </a>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				size: this.$bus.beian,
				company: this.$bus.company,
				score: this.$context(this.$bus.img.score),
				circle: this.$context(this.$bus.img.circle),
				app_img_1: this.$context(this.$bus.img.app_img_1),
				app_img_2: this.$context(this.$bus.img.app_img_2)
			}
		},
		methods: {
			openUrl() {
				// window.open(this.$bus.download_url)
				this.$router.push('/home/download')
			}
		},
		computed: {
			year() {
				return new Date().getFullYear();
			}
		}
	}
</script>

<style lang="less">
	#home {

		.head {
			display: flex;
		}

		.space {
			padding-top: 45.283018px;
			padding-left: 16.037735px;
			width: 113.207547px;
			padding-right: 11.886792px;

			.name {
				color: #505050;
				font-size: 4.528301px;
				line-height: 6.792452px;
				margin-bottom: 3.773584px;
			}

			.title {
				color: #010101;
				font-size: 9.433962px;
				line-height: 13.962264px;
				font-weight: 900;
				margin-bottom: 7.547169px;
			}

			.text {
				color: #505050;
				font-size: 4.528301px;
				line-height: 6.792452px;
				margin-bottom: 7.547169px;
			}

			.download {
				border: none;
				outline: none;
				margin: 0;
				padding: 0;
				background-color: #2a2118;
				color: white;
				font-size: 4.528301px;
				font-weight: bold;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 66.037735px;
				height: 16.603773px;
				border-radius: 1.509433px;
				cursor: pointer;
			}

			.score {
				margin-top: 28.867924px;

				.ment {
					line-height: 6.792452px;
					font-size: 4.528301px;
					color: #010101;
					font-weight: 900;
				}

				img {
					margin-top: 3.773584px;
					width: 45.283018px;
					height: 8.301886px;
					margin-bottom: 3.773584px;
				}

				.mount {
					color: #505050;
					line-height: 6.792452px;
					font-size: 4.528301px;
				}
			}
		}

		.introduce {
			position: relative;
			width: 100%;

			.circle1,
			.circle2 {
				position: absolute;
				width: 110.377358px;
				height: 110.377358px;

			}

			.circle1 {
				top: 31.354716px;
				left: 0;
			}

			.circle2 {
				top: 86.637735px;
				left: 86.792452px;
			}

			.friend {
				// position: absolute;
				// left: 17.35849px;
				// top: 21.543396px;
				// z-index: 10;
				width: 75.471698px;
				// height: 163.420754px;
				margin-top: 20px;
				margin-left: 20px;
				margin-right: 20px;
			}

			.home {
				// position: absolute;
				width: 75.471698px;
				// height: 163.420754px;
				// left: 104.150943px;
				// top: 74.373584px;
				position: relative;
				top:20px;
			}
		}

		.copy {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 40.320754px;
			padding-bottom: 11.320754px;
		}
	}
	
	@media screen and (max-width:992px) {
		#home {
		
			.head {
				// height: 500.150943px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
		
			.space {
				padding-top: 45.283018px;
				padding-left: 16.037735px;
				width: 250.207547px;
				padding-right: 11.886792px;
		
				.name {
					color: #505050;
					font-size: 14.528301px;
					line-height: 20.792452px;
					margin-bottom: 8.773584px;
				}
		
				.title {
					color: #010101;
					font-size: 20.433962px;
					line-height: 13.962264px;
					font-weight: 900;
					margin-bottom: 17.547169px;
				}
		
				.text {
					color: #505050;
					font-size: 12.528301px;
					line-height: 20.792452px;
					margin-bottom: 17.547169px;
				}
		
				.download {
					border: none;
					outline: none;
					margin: 0;
					padding: 0;
					background-color: #2a2118;
					color: white;
					font-size: 12.528301px;
					font-weight: bold;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 160.037735px;
					height: 30.603773px;
					border-radius: 3.509433px;
					cursor: pointer;
				}
		
				.score {
					margin-top: 28.867924px;
		
					.ment {
						// line-height: 6.792452px;
						font-size: 20.528301px;
						color: #010101;
						font-weight: 900;
					}
		
					img {
						margin-top: 13.773584px;
						width: 57.283018px;
						height: 10.301886px;
						margin-bottom: 3.773584px;
					}
		
					.mount {
						color: #505050;
						line-height: 6.792452px;
						font-size: 6.528301px;
					}
				}
			}
		
			.introduce {
				position: static!important;
				width: 100%;
				padding:10px 10px;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
		
				.circle1,
				.circle2 {
					position: absolute;
					width: 110.377358px;
					height: 110.377358px;
		
				}
		
				.circle1 {
					top: 400.354716px;
					left: 0;
				}
		
				.circle2 {
					top: 1230.637735px;
					left: 186.792452px;
				}
		
				.friend {
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
					position: static;
					width: 100%!important;
					// height: auto!important;
					margin: 30px 0;
					z-index: 100;
				}
				
				.home {
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
					position: static;
					width: 100%!important;
					// height: auto!important;
					z-index: 100;
				}
			}
			.copy {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				padding-top: 40.320754px;
				padding-bottom: 11.320754px;
				font-size: 8px;
			}
		}
	}
</style>
