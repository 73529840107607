import { render, staticRenderFns } from "./clause.vue?vue&type=template&id=32ebbe7e&scoped=true&"
var script = {}
import style0 from "./clause.vue?vue&type=style&index=0&id=32ebbe7e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ebbe7e",
  null
  
)

export default component.exports