<template>
  <div id="about">
   <div class="head">
      <div class="leftout">
      <div class="eng">SOFTWARE INTRODUCTION</div>
      <div class="title">软件介绍</div>
      <div class="text">
      <p v-for=" (li,index) in  $bus.about_text" :key="index" v-html="li"></p>
      </div>
    </div>
    <div class="rightout">
      <img :src="circle" class="circle1">
       <img :src="circle" class="circle2">
       <img :src="app_img_3" class="friend">
         <img :src="app_img_4" class="home">
    </div>
   </div>
    <div class="copy">
    	<span>
    		Copyright ©{{year}} {{company}} 增值电信业务经营许可证:<a href="https://beian.miit.gov.cn/"> {{size}} </a>
    	</span>
    </div>
  </div>
</template>

<script>
export default {
data(){
  return {
    size: this.$bus.beian,
    company:this.$bus.company,
    score:this.$context(this.$bus.img.score),
    circle:this.$context(this.$bus.img.circle),
    app_img_3:this.$context(this.$bus.img.app_img_3),
    app_img_4:this.$context(this.$bus.img.app_img_4)
  }
}
,
computed:{
    year(){
        return new Date().getFullYear();
    }
}
}
</script>

<style lang="less">
p {
  margin: 0;
}
  #about {
    .head{
       display: flex;
    }
    .leftout {
      margin-left: 16px;
      margin-top: 45px;
      padding-right: 11.88px;
      .eng {
        color: #505050;
        line-height: 6.79px;
        font-size: 4.52px;
        margin-bottom: 3.77px;
      }
      .title {
        color: #010101;
        font-size: 9.43px;
        line-height: 13.96px;
        font-weight: 900;
        margin-bottom: 7.55px;
      }
      .text{
         width: 113.20px;
      }
      .text p{
        color: #505050;
        font-size: 5.52px;
        line-height: 6.79px;
       
      }
    }
    .rightout {
      position: relative;
     
      .circle1 ,.circle2{
        position: absolute;
      width: 110.37px;
      height: 110.37px;
       
      }
       .circle1{
         top: 31.35px;
        left: 0;
       }
       .circle2{
         top: 86.64px;
         left: 86.79px;
       }
        // .friend {
        //   position: absolute;
        //   left: 17.55px;
        //   top: 21.53px;
        //   z-index: 10;
        //   width: 75.47px;
        //   height: 163.42px;
        // }
        // .home {
        //   position: absolute;
        //   width: 75.47px;
        //   height: 163.42px;
        //   left: 104.15px;
        //   top: 74.37px;
        // }
				
				.friend {
					// position: absolute;
					// left: 17.35849px;
					// top: 21.543396px;
					// z-index: 10;
					width: 75.471698px;
					// height: 163.420754px;
					margin-top: 20px;
					margin-left: 20px;
					margin-right: 20px;
				}
				
				.home {
					// position: absolute;
					width: 75.471698px;
					// height: 163.420754px;
					// left: 104.150943px;
					// top: 74.373584px;
					position: relative;
					top:20px;
				}
   }
     .copy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11.32px;
    padding-bottom: 11.32px;
}
  }
	
	@media screen and (max-width:992px) {
		#about {
		    .head{
		     display: flex;
		     flex-wrap: wrap;
		     justify-content: center;
				 
		    }
		    .leftout {
		      margin-left: 16px;
		      margin-top: 45px;
		      padding-right: 11.88px;
		      .eng {
		        color: #505050;
		        line-height: 6.79px;
		        font-size: 14.52px;
		        margin-bottom: 13.77px;
		      }
		      .title {
		        color: #010101;
		        font-size: 19.43px;
		        line-height: 13.96px;
		        font-weight: 900;
		        margin-bottom: 7.55px;
		      }
		      .text{
		         width: 100%;
		      }
		      .text p{
		        color: #505050;
		        font-size: 9.52px;
		        line-height: 16.79px;
		      }
		    }
		    .rightout {
		      position: static!important;
		      width: 100%;
		      padding:10px 10px;
		      display: flex;
		      justify-content: center;
		      flex-wrap: wrap;
		     
		      .circle1 ,.circle2{
		        position: absolute;
		      width: 110.37px;
		      height: 110.37px;
		       
		      }
		       .circle1 {
		       	top: 400.354716px;
		       	left: 0;
		       }
		       		
		       .circle2 {
		       	top: 1230.637735px;
		       	left: 186.792452px;
		       }
		        .friend {
		        	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
		        	position: static;
		        	width: 100%!important;
		        	// height: auto!important;
		        	margin: 30px 0;
		        	z-index: 100;
		        }
		        
		        .home {
		        	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
		        	position: static;
		        	width: 100%!important;
		        	// height: auto!important;
		        	z-index: 100;
		        }
		   }
		     .copy {
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  text-align: center;
		  padding-top: 40.320754px;
		  padding-bottom: 11.320754px;
		  font-size: 8px;
		}
		  }
	}
</style>